@use "sass:map";

// COLORS

$theme-colors: (
  primary: #FDCB29, 
  primary-500: #FDCB29,
  primary-600: #fdb416,
  primary-800: #C0841B,
  primary-900: #8C6A1E, 

  // Deprecated
  primary-light: #ECA8A8,
  primary-lighter: #f3d4d4,

  secondary: #9B59D0,
  secondary-300: #b383da,
  secondary-500: #9B59D0,
  secondary-700: #7E40B0,
  secondary-900: #4f3267,
  secondary-muted: #64497B,

  danger: #f40804, 
  success: #3e8d63,

  dark: #181818,
  dark-900: #181818,
  dark-700: #2D2D2D,
  dark-500: #5b5858,
  dark-400: #767676,
  
  purple-color: #a69fb9cb,
  purple-darker: #443C5A,
  purple-dark: #241F31,
  purple-light: #d5caebef,
  
  light: #ffffffef,
  light-100: #FBFBFB,
  light-200: #EAEAEA,
  light-300: #D6D6D6,
  light-400: #BDBDBD,

  neutral-500: #979494,

  header-color: #EACCCC,
  yellow-color: #D99600,
  yellow-dark-color: #a06e03,
);

$primary-color:                     map.get($theme-colors, 'primary') !default;
$primary-600-color:                 map.get($theme-colors, 'primary-600') !default;
$primary-800-color:                 map.get($theme-colors, 'primary-800') !default;
$primary-900-color:                 map.get($theme-colors, 'primary-900') !default;

// Deprecated
$primary-light-color:               map.get($theme-colors, 'primary-light') !default;
// Deprecated
$primary-light-hover-color:         map.get($theme-colors, 'primary-lighter') !default;

$secondary-color:                   map.get($theme-colors, 'secondary') !default;
$secondary-300-color:               map.get($theme-colors, 'secondary-300') !default;
$secondary-400-color:               map.get($theme-colors, 'secondary-400') !default;
$secondary-500-color:               map.get($theme-colors, 'secondary-500') !default;
$secondary-700-color:               map.get($theme-colors, 'secondary-700') !default;
$secondary-900-color:               map.get($theme-colors, 'secondary-900') !default;
$secondary-muted-color:             map.get($theme-colors, 'secondary-muted') !default;

$link-color:                        $primary-color;

$dark-color:                        map.get($theme-colors, 'dark') !default;
$dark-900-color:                    map.get($theme-colors, 'dark-900') !default;
$dark-700-color:                    map.get($theme-colors, 'dark-700') !default;
$dark-500-color:                    map.get($theme-colors, 'dark-500') !default;
$dark-400-color:                    map.get($theme-colors, 'dark-400') !default;

$light-100-color:                    map.get($theme-colors, 'light-100') !default;
$light-200-color:                    map.get($theme-colors, 'light-200') !default;
$light-300-color:                    map.get($theme-colors, 'light-300') !default;
$light-400-color:                    map.get($theme-colors, 'light-400') !default;

$neutral-500-color:                 map.get($theme-colors, 'neutral-500') !default;

$headings-color:                    map.get($theme-colors, 'primary') !default;
$yellow-color:                      map.get($theme-colors, 'yellow-color') !default;
$yellow-dark-color:                 map.get($theme-colors, 'yellow-dark-color') !default;
$purple-color:                      map.get($theme-colors, 'purple-color') !default;
$purple-darker:                     map.get($theme-colors, 'purple-darker') !default;
$purple-light-color:                map.get($theme-colors, 'purple-light') !default;



// FONT SIZES

$font-size-base:                1rem !default; 
$navbar-brand-font-size:        $font-size-base * 1.45 !default;
$nav-link-font-size:            $font-size-base * 1.2  !default;

$h1-font-size:                $font-size-base * 2.1 !default;
$h2-font-size:                $font-size-base * 1.8 !default;
$h3-font-size:                $font-size-base * 1.5 !default;
$h4-font-size:                $font-size-base * 1.3 !default;
$h5-font-size:                $font-size-base * 1.1 !default;
$h6-font-size:                $font-size-base !default;
