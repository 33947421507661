$breakpoint-xs: 0;         // Extra small (default)
$breakpoint-sm: 576px;     // Small (mobile)
$breakpoint-md: 768px;     // Medium (tablet)
$breakpoint-lg: 992px;     // Large (desktop)
$breakpoint-xl: 1200px;    // Extra large (large desktop)
$breakpoint-xxl: 1400px;   // Extra extra large (very large screens)

// General breakpoint mixin
@mixin breakpoint($breakpoint) {
    @media (min-width: $breakpoint) {
        @content;
    }
}

@mixin mobile {
    @include breakpoint($breakpoint-sm) {
        @content;
    }
}

@mixin tablet {
    @include breakpoint($breakpoint-md) {
        @content;
    }
}

@mixin desktop {
    @include breakpoint($breakpoint-lg) {
        @content;
    }
}

@mixin large-desktop {
    @include breakpoint($breakpoint-xl) {
        @content;
    }
}

@mixin extra-large {
    @include breakpoint($breakpoint-xxl) {
        @content;
    }
}