@import "./mixins";

.container {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 0.85rem;

    &.container-wide {
        max-width: 1200px;

        @include desktop {
            width: 1200px;
        }
    }

    @include desktop {
        padding: 67px 131px;
        align-items: center;
        gap: 1rem;
        align-self: stretch;
    }
}

body.sidebar-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.text-truncate-1,
.text-truncate-2,
.text-truncate-3,
.text-truncate-4 {
    word-break: break-word;
}

section {
    display: flex;
    flex-direction: column;
    align-items: flex-center;

    padding: 1rem;

    @include desktop {
        padding: 96px 300px;
    }
    h2 {
        // padding-left: 131px;
        // padding-right: 131px;
        @include desktop {
            width: 100%;
            text-align: left;
        }
    }
}