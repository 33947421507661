body {
    font-family: 'Geist Mono';

    &.theme-dark {
        background-color: $dark-900-color;
        color: $light-200-color;
    }
    &.theme-light {
        background-color: $light-100-color;
        color: $dark-900-color;
    }
}

a {
    color: $secondary-color;
}

.theme-dark {
    h1, h2, h3, h4, h5, h6 {
        color: $light-100-color;
    }
    input.form-check-input {
        background-color: $dark-900-color;
    
        &:checked[type=checkbox] {
            background-color: $secondary-color;
        }
    }
    .tag {
        &.primary {
            background-color: none;
            border: 1px solid $primary-color;
            color: $primary-color;
        }
        &.secondary {
            background-color: none;
            border: 1px solid $secondary-color;
            color: $secondary-color;
        }
    }
    .accordion-item {
        background-color: $dark-900-color;
        border-bottom: 1px solid $light-100-color;

        .accordion-button {
            background-color: $dark-900-color;
            border-bottom: 1px solid $light-100-color;
            color: $light-100-color;

            &::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); 
            }
            
            &:not(.collapsed) {
                background-color: $dark-900-color;
                color: $light-100-color;
                box-shadow: none;

                &::after {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                }
            }
          
        }
        .accordion-body {
            color: $light-100-color;
        }
    }
    button {
        &.btn-secondary {
            background-color: $secondary-300-color;
            border: none;
        }
    }
}
.theme-light {
    h1, h2, h3, h4, h5, h6 {
        color: $dark-900-color;
    }
    input.form-check-input {
        &:checked[type=checkbox] {
            background-color: $secondary-color;
        }
    }
    .tag {
        color: $dark-900-color;
        &.primary {
            background-color: $primary-color;
        }
        &.secondary {
            background-color: $secondary-300-color;
        }
    }
    .accordion-item {
        background-color: $light-100-color;
        border-bottom: 1px solid $dark-900-color;

        .accordion-button {
            background-color: $light-100-color;
            
            &:not(.collapsed) {
                background-color: $light-100-color;
                color: $dark-900-color;
            }
        }
    }
    button {
        &.btn-secondary {
            background-color: $secondary-400-color;
            color: #fff;
            border: none;
        }
    }
}
