@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_maps";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_utilities";

$utilities: map-merge( 
    $utilities, ( 
        "width": map-merge( 
            map-get($utilities, "width"), 
            ( 
                responsive: true,
                values: map-merge(
                    map-get(map-get($utilities, "width"), "values"),
                    (33: 33.33%),
                ),
            )
        ),
    )
);

@import "bootstrap/scss/utilities/_api";

.cursor-pointer {
    cursor: pointer;
}