@use "sass:map";
@import "./_variables.scss";

a.text-primary-light:hover,
.nav-link.text-primary-light:hover {
  color: $primary-light-hover-color !important;
}

body {
  //color: map.get($theme-colors, 'white');
}

button.btn {
  .btn-primary {
    &:hover {
      color: $dark-900-color;
    }
  }
}